<template>
    <inquiry-form-field field-name="section">
        <inquiry-form-sections-select-field name="section" v-model="selected" :sections="sections" required />
    </inquiry-form-field>
</template>

<script>
export default {
    name: 'InquiryFormSections',
    props: { value: [Number, String], pricingTier: [Object, Boolean] },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(index) {
                this.$emit('input', index);
            }
        },
        sections() {
            return this.pricingTier ? this.pricingTier.pricingChart : []
        }
    },
    methods: {
    },
};
</script>
