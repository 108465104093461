<template>
    <fieldset class="field">
        <label>Performance Date</label>
        <v-date-picker :input-props="{readonly: true}" :masks="{input:['WWWW MMMM D, YYYY']}" v-model="selectedDate" v-if="performances.length" :available-dates="availableDates" :min-page="startMonth" :max-page="endMonth"></v-date-picker>
    </fieldset>
</template>

<script>
import moment from 'moment';
export default {
    name: 'InquiryFormPerformanceDate',
    props: ['performances', 'value'],
    computed: {
        selectedDate: {
            get() {
                return this.value;
            },
            set(date) {
                this.$emit('input', date);
            },
        },
        availableDates() {
            return this.performances
                .map((performance) => performance.dateId)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort();
        },
        startMonth() {
            return this.formatMonth(this.availableDates[0]);
        },
        endMonth() {
            return this.formatMonth(this.availableDates[this.availableDates.length - 1]);
        },
    },
    methods: {
        formatMonth(day) {
            const date = moment(day);
            return { month: parseInt(date.format('M'), 10), year: parseInt(date.format('Y'), 10) };
        },
    },
};
</script>
