<template>
    <div class="calendar-day__performances">
        <a v-for="performance in uniquePerformances" :key="performance.id" :href="'#'+performance.pricingTierCode || null">
            {{ performance.time }} <span>{{ performance.pricingTierCode }}</span>
        </a>
    </div>
</template>

<script>
    export default {
        name: "PerformanceLinks",
        props: {
            day: Object,
            performances: Array
        },
        computed: {
            uniquePerformances() {
                return this.performances && this.performances.reduce((acc, performance) => {
                    if (!acc.some(existingPerformance => existingPerformance.foxtrotPerformanceId === performance.foxtrotPerformanceId)) {
                        acc.push(performance);
                    }
                    return acc;
                }, []);
            }
        }
    }
</script>
